<template>
  <CContainer id="activeUser">
    <LoginFormComponent
      :formType="isExpiredUser==true? 'LINK_EXPIRED_USER' : 'ACTIVE_USER'"
      :formTitle="isExpiredUser==true ? 'Link expired' : 'Active user'"
      :btnLabel="isExpiredUser==true? '' : 'Update'"
      :label1="isExpiredUser==true? '' : 'Password'"
      text6
      :label2="isExpiredUser==true? '' : 'Confirm Password'"
      @update="update"
      :rulesRequired="true"
      :rulesMinPassword="true"
      :rulesConfirmed="true"
    />
  </CContainer>
</template>

<script>
import _ from 'lodash';
import LoginFormComponent from '@/components/LoginFormComponent';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'ActiveUser',
  data() {
    return {
      token: '',
      isExpiredUser: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(async vm => {
      const token = _.trim(to.query.token);

      try {
        const res = await vm.$http.get(endpoints.checkToken(token));
        if (res.data) {
          const tokenStatus = res.data.token_sts;
          const userStatus = res.data.user_sts;
          if (tokenStatus == 'VALID' && userStatus == 'UNACTIVATED') {
            next();
          } else {
            vm.$notify({
              group: 'notifications',
              type: 'error',
              title: 'Error',
              text: `TOKEN_${tokenStatus}`,
            });
            next('/login');
          }
        }
      } catch (err) {
        next('/login');
        vm.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    });
  },
  async mounted() {
    const token = _.trim(this.$route.query.token);
    this.token = token;

    const resultTokens = await this.$http.get(endpoints.checkToken(this.token));
    if (resultTokens.data.token_sts !== 'VALID') {
      this.isExpiredUser = true;
    }
  },
  components: { LoginFormComponent },
  methods: {
    async update({ password }) {
      try {
        const params = {
          verify_token: this.token,
          password: password,
        };
        const result = await this.$http.post(endpoints.activeUser, params);

        if (result) {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Success',
            text: 'User has been activated!',
          });
          this.toLogin();
        }
      } catch (err) {
        if (err.code === 'TOKEN_EXPIRED') {
          this.isExpiredUser = true;
          return;
        }

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Active',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    toLogin() {
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
#activeUser{
  .custom-card-body{
    .form-group{
      &.input--w{
        label{
          width: 145px;
          text-align: right;
          padding-right: 15px !important;
        }
        input{
          width: calc(100% - 145px);
          @media(max-width: 1024px){
            width: 70%;
          }
          @media(max-width: 767px){
            width: calc(100% - 145px);
          }
        }
      }
      &.input2{
        .input-group{
          width: calc(100% - 145px);
          @media(max-width: 1024px){
            width: 70%;
          }
          @media(max-width: 767px){
            width: calc(100% - 145px);
          }
        }
        label{
          width: 145px;
          text-align: right;
        }
      }
    }
  }
  .error-msg-wrap{
    display: flex;
    justify-content: flex-end;
    .error-msg{
      width: calc(100% - 145px);
      padding-left: 0 !important;
      @media(max-width: 1024px){
        width: 70%;
      }
      @media(max-width: 767px){
        width: calc(100% - 145px);
      }
    }
  }
}
</style>
